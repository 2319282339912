<template>
  <div class="page">
    <!--pages/mypush/mypush.wxml-->
    <div class="head">
      <div class="item line1 flexcenter">
        <div>当前佣金（元）</div>
        <div>昨日收益</div>
      </div>
      <div class="item line2 flexcenter">
        <div>{{spread_amount}}</div>
        <div>+{{yestoday_amount}}</div>
      </div>
      <div class="item line3 flexcenter">
        <div>累计已结  {{have_spread_amount}}</div>
        <div>提现记录 ></div>
      </div>
    </div>
  
    <div class="ljtx flexcenter">
      <div></div>
      <div>立即提醒</div>
      <div>></div>
    </div>
  
    <div class="list">
      <div class="item flexcenter" @click="Tips()">
        <img class="tg-img" mode="widthFix" src="https://www.2gooo.cn/uploads/wxmini/images/mypush/tgrmp.png">
        <div>推广名片</div>
      </div>
    
      <router-link class="item flexcenter" :to="{name: 'mypushhelpers'}">
        <img class="tg-img" mode="widthFix" src="https://www.2gooo.cn/uploads/wxmini/images/mypush/tgrtj.png">
        <div>推广人统计</div>
      </router-link>
    
      <router-link class="item flexcenter" :to="{name: 'bonuslist'}">
        <img class="tg-img" mode="widthFix" src="https://www.2gooo.cn/uploads/wxmini/images/mypush/yjmx.png">
        <div>佣金明细</div>
      </router-link>
    
      <router-link class="item flexcenter" :to="{name: 'mypushhelperorders'}">
        <img class="tg-img" mode="widthFix" src="https://www.2gooo.cn/uploads/wxmini/images/mypush/tgrdd.png">
        <div>推广人订单</div>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'mypush',
    data() {
      return {
        spread_amount:0, //待结佣金
        have_spread_amount:0, //已结佣金
        yestoday_amount:0, //昨日收益
      }
    },
    created() {
      this.getDetail()
    },
    methods: {
		Tips:function(){
			this.$toast("此渠道暂未支持")
		},
      /**
       * 获取推广员信息
       */
      getDetail:function(){
        let that = this;
        this.post(
          this.API.spreadDetail,{}
        ).then(response => {
          if(response.data.err=='1') {
            this.$toast(response.data.msg);
            setTimeout(function(){
              this.router.back()
            },1000)
          }else{
            this.spread_amount = response.data.detail.spread_amount
            this.have_spread_amount = response.data.detail.have_spread_amount
            this.yestoday_amount = response.data.detail.yestoday_amount
          }
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
  }
  .head {
    padding: 32px;
    background-color: #F8D414;
  }
  .head .item {
    justify-content: space-between;
  }
  .line1 {
    font-size: 24px;
  }
  .line2 {
    margin: 12px 0 33px;
    font-size: 56px;
    font-weight: bold;
  }
  .line3 {
    font-size: 28px;
  }

  .ljtx {
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 32px;
    height: 102px;
    background-color: #FFFFFF;
    font-size: 28px;
    font-weight: bold;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 24px;
  }
  .list .item {
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 24px;
    width: 339px;
    height: 200px;
    background-color: #FFFFFF;
    border-radius: 20px;
    font-size: 28px;
  }
  .list .item .tg-img {
    margin-bottom: 32px;
    width: 64px;
  }

</style>
